import { Component } from "react";
import "../../../styles/css/header.scss";
import "../../../styles/css/dashboard.scss";
import { Modal } from 'react-bootstrap';
import apis from "../../../Providers.Api/apis";
import HelperFunctions from "../../../Common/Helper";
import { appContext } from "../../../AppContext";
import OnBoarding from "../OnBoarding/OnBoarding";
import NotificationIcon from "../../Icons/NotificationIcon";
import { connect } from "react-redux";
import { SWITCH_THEME } from "../../../app/constants";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IbssDialog from "../../Dialogs/BaseDialog/IbssDialog";
import { IUserPreferencesNode } from "../../../Providers.Api/UserPreferences/UserPreferenceRepository";
import IbssButtonRedo from "../../Buttons/Button/IbssButton";
import { RouteComponentProps } from "react-router-dom";
import { IPropsFromState } from "../../../redux/Interfaces";
import IbssButton from "../../Buttons/Button/IbssButton";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { IFile } from "../../../Providers.Api/Files/FilesRepository";
import Helper from "../../../Common/Helper";
import IbssToolTip from "../../Miscellaneous/Tooltip/IbssToolTip";

export class Header extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            title: "",
            isModalShow: false,
            isShowDiagnosticsModal: false,
            IBSS_Hub_Version: "",
            IBSS_API: "",
            IBSS_User_API: "",
            IBSS_Data_Entry_API: "",
            IBSS_Data_API: "",

            showMenu: false,
            show: false,
            oldPassword: "",
            newPassword: "",
            reTypePassword: "",
            type: "",
            email: "",
            valid: true,
            showError: false,
            errorMessage: "",
            whatsNewLink: "",
            onBoardingModal: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ title: i.pageTitle }));

        if (this.appState.customerLogo.length == 0)
        {
            this.getCustomerLogo();
        }
    
        let IBSS_Hub_Version = ""
        try
        {
            IBSS_Hub_Version = "1.24." + process.env.REACT_APP_VERSION ? "1.24." + process.env.REACT_APP_VERSION : ""
        } catch (error)
        {
            IBSS_Hub_Version = ""
        }

        this.setState({
            type: this.local.getUserDetails().userType,
            email: this.local.getUserDetails().email,
            IBSS_Hub_Version: IBSS_Hub_Version,
        });
        let url = window.location.href;
        const userPref = this.local.getUserPreferences();

        if (url.includes('flex') && (userPref.SearchPrefs === null || userPref.NotificationPrefs === null))
        {
            this.setState({ onBoardingModal: true });
            return; // do not continue executing code that depends on userPref.SearchPrefs.DefaultBuilding
        }

        const buildingId = userPref.SearchPrefs.DefaultBuilding;
        const DefaultFloor = userPref.Nodes.find((i: IUserPreferencesNode) => i.NodeId == buildingId)?.DefaultFloor as number | null;

        if (url.includes('flex') && (userPref.SearchPrefs.DefaultBuilding == 1 || userPref.SearchPrefs.DefaultBuilding == null || userPref.SearchPrefs.DefaultBuilding == undefined) || (DefaultFloor == 0 || DefaultFloor == null || DefaultFloor == undefined))
        {
            this.setState({ onBoardingModal: true })
        }
    }

    private async getCustomerLogo(): Promise<void>
    {   
        try 
        {
            const getCustomerLogo = await this.apiClient.files.getFiles('c/logo');    
            const customerLogoPath = getCustomerLogo.map((item: IFile) => {
                let newItem = { ...item }
                newItem.path = `https://storage.ibss.${Helper.environment}/images/c/logo/${item.name}`
                return newItem
            })
            this.appState.set({customerLogo:customerLogoPath})
        } catch (error) 
        {    
        }
    }

    private toggleUserMenu(): void
    {
        this.setState({ showMenu: !this.state.showMenu });
    };

    private updateTheme(): void
    {
        const { lightModeTheme } = this.props;
        sessionStorage.setItem("themeMode", "" + (!lightModeTheme))
    }

    private getMainTitle(pageTitle: string): string
    {
        let url = window.location.href;
        let currentPage = pageTitle;

        if (!url.includes(pageTitle))
        {
            if (url.includes('security'))
            {
                currentPage = this.labels.HubMenuSecurity;
            }
            if (url.includes('portfolio-setup'))
            {
                currentPage = this.labels.HubMenuportfolioText;
            }
            if (url.includes('notifications'))
            {
                currentPage = this.labels.HubMenuNotifications;
            }
            if (url.includes('operational-services') || url.includes('catering-orders') || url.includes('catering-items') || url.includes('create-catering-order'))
            {
                const buildingName = this.session.getBuildingName();
                if (buildingName)
                {
                    currentPage = this.labels.HubLabelFacilityManagementText + buildingName;
                }
                else
                {
                    currentPage = this.labels.HubLabelFacilityManagement;
                }
            }
            if (url.includes('flex-my-tasks'))
            {
                currentPage = this.labels.HubLabelMyTask;
            }
            if (url.includes('flex-my-tasks-to-resolve'))
            {
                currentPage = this.labels.HubMenuMyTaskstoResolve;
            }
            if (url.includes('flex-my-bookings'))
            {
                currentPage = this.labels.HubMenumySchedule;
            }
            if (url.includes('flex-my-visitor'))
            {
                currentPage = this.labels.HubMenumySchedule;
            }
            if (url.includes('norights'))
            {
                currentPage = '';
            }
            if (url.includes('filemgmt'))
            {
                currentPage = this.labels.HubMenuFileManagement;
            }
            if (url.includes('booking-policies'))
            {
                currentPage = this.labels.HubMenuBookingPolicies;
            }
            if (url.includes('operational-cost-codes'))
            {
                currentPage = this.labels.HubLabelCostCodes;
            }
            if (url.includes('flex-recurring-booking'))
            {
                currentPage = this.labels.HubMenuSearchaSpace;
            }
            if (url.includes('operational-services-tasks'))
            {
                currentPage = this.labels.HubMenuTasks;
            }
            if (url.includes('operational-services-visitors-dashboard'))
            {
                currentPage = this.labels.HubLabelOperationalServicesVisitors;
            }
        } else
        {
            this.session.removeBuildingName();
            this.session.removeBuildingId();
        }
        return currentPage;
    }

    private handleModal(): void
    {
        this.setState({
            show: !this.state.show,
            oldPassword: "",
            newPassword: "",
            reTypePassword: "",
            email: "",
        })
    }

    private change(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ [e.target.name]: e.target.value } as any, () =>
        {
            if (this.state.newPassword === this.state.reTypePassword && this.state.newPassword.length > 0 && this.state.oldPassword.length > 0)
            {
                this.setState({
                    valid: false
                })
            } else
            {
                this.setState({
                    valid: true
                })
            }
        });

    }

    private handleDiagnosticsModal(): void
    {
        if (!this.state.isShowDiagnosticsModal)
        {
            this.loadVersionNumbers();
            this.setState({ isShowDiagnosticsModal: true });
        }
        else
        {
            this.setState({ isShowDiagnosticsModal: false });
        }
    }

    private async loadVersionNumbers(): Promise<void>
    {
        try
        {
            const response = await this.apiClient.versionInfo.getIbssApiVersion();
            this.setState({ IBSS_API: response.Version });
        }
        catch
        {
            this.setState({ IBSS_API: "" });
        }

        try
        {
            const response = await this.apiClient.versionInfo.getUserApiVersion();
            this.setState({ IBSS_User_API: response.version });
        }
        catch
        {
            this.setState({ IBSS_User_API: "" });
        }

        try
        {
            const response = await this.apiClient.versionInfo.getDataEntryApiVersion();
            this.setState({ IBSS_Data_Entry_API: response.version });
        }
        catch
        {
            this.setState({ IBSS_Data_Entry_API: "" });
        }

        try
        {
            const response = await this.apiClient.versionInfo.getDataApiVersion();
            this.setState({ IBSS_Data_API: response.version });
        }
        catch
        {
            this.setState({ IBSS_Data_API: "" });
        }
    }

    private submit(): void
    {
        let payload = {
            "email": this.local.getUserDetails().email,
            "oldPassword": this.state.oldPassword,
            "newPassword": this.state.newPassword
        }

        apis.resetNativePassword(payload).then((res) =>
        {
            // alert("Password Updated successfully")
            this.handleModal();
        }).catch((error: any) =>
        {
            let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
            if (error?.response?.data?.message)
            {
                errorMessage = error?.response?.data?.message
                this.handleModal();
                this.setState({ showError: true, errorMessage: errorMessage })
            } else
            {
                this.setState({ showError: true, errorMessage: errorMessage })
            }
        });
    }

    private closeErrorModal(): void
    {
        this.setState({ showError: false, errorMessage: "" });
    }

    private redirectToNotification(): void
    {
        const { history } = this.props;
        history.push('/flex-my-notification');
    }

    private get releaseVersion(): string
    {
        const versionMatches = this.state.IBSS_Hub_Version.match(/^(\d+)\.(\d+)/);
        const major = parseInt(versionMatches?.[1] ?? "0");
        const minor = parseInt(versionMatches?.[2] ?? "0");

        if (major == 1 && minor == 22)
        {
            return '2023.02';
        }
        else if (major == 1 && minor == 23)
        {
            return '2024.01';
        }
        else if (major == 1 && minor == 24)
        {
            return '2024.02';
        }
        else
        {
            return '';
        }
    }

    public render(): JSX.Element
    {
        const { lightModeTheme, dispatch, mainPageTitle, spaceTitle, newNotification }: any = this.props;
        const displayName = this.local.getUserDetails().displayName;
        let mainTitle = this.getMainTitle(mainPageTitle);
       const {customerLogo } = this.appState
        const logoPath = customerLogo ? (lightModeTheme
            ? (customerLogo.find(logo => logo && logo.path?.includes("light")))?.path
            : (customerLogo.find(logo => logo && logo.path?.includes("dark")))?.path)
            : null;

        return (
            <>
                {/* <LoadingOverlay /> */}

                <div className="header d-flex js-space-arround">
                    <IbssDialog
                        open={this.state.showError}
                        onClose={() => this.closeErrorModal()}
                        fullWidth
                        header=
                        {
                            <label>{this.labels.HubLabelerrorSomethingWentWrong}</label>
                        }
                        dialogContent={this.state.errorMessage}
                        footer=
                        {
                            <IbssButton
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="mr-2"
                                tabIndex={0}
                                onClick={() => this.closeErrorModal()}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButton>
                        }
                    />

                    <IbssDialog
                        open={this.state.show}
                        fullWidth
                        header=
                        {
                            <label>{this.labels.HubLabelChangePassword}</label>
                        }
                        dialogContent=
                        {
                            <>
                                <div className="col-12">
                                    <label>{this.labels.HubLabelOriginalPassword}</label>
                                    <input className="input-box border model-textbox" type="password" name="oldPassword" value={this.state.oldPassword} onChange={(e) => { this.change(e); }} />
                                </div>
                                <div className="col-12">
                                    <label>{this.labels.HubLabelNewPassword}</label>
                                    <input className="input-box border model-textbox" type="password" name="newPassword" value={this.state.newPassword} onChange={(e) => { this.change(e); }} />
                                </div>
                                <div className="col-12">
                                    <label>{this.labels.HubLabelReType} </label>
                                    <input className="input-box border model-textbox" type="password" name="reTypePassword" value={this.state.reTypePassword} onChange={(e) => { this.change(e); }} />
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButton
                                    onClick={() => this.handleModal()}
                                    color="secondary"
                                    variant="outlined"
                                    className="mr-2"
                                    tabIndex={0}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButton>
                                <IbssButton
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    className="ml-2"
                                    disabled={this.state.valid}
                                    onClick={() => this.submit()}
                                    tabIndex={0}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButton>
                            </>
                        }
                        onClose={() => this.handleModal()}
                    />

                    <IbssDialog
                        open={this.state.isShowDiagnosticsModal}
                        fullWidth
                        onClose={() => this.handleDiagnosticsModal()}
                        header={this.labels.HubLabelDiagnostics}
                        dialogContent=
                        {
                            <Box>
                                <Box display={'flex'}>
                                    <Box width={'40%'} className="d-flex justify-content-end" mr={1}>
                                        <Typography className="diagnostic-text">Release Version:</Typography>
                                    </Box>
                                    <Box>
                                    <Typography className="diagnostic-text">{`${this.releaseVersion || this.labels.funcUnknown_S} \n`}</Typography>
                                    </Box>
                                </Box>
                                <hr/>
                                <Box display={'flex'}>
                                    <Box width={'40%'} className="d-flex justify-content-end" mr={1}>
                                        <Typography className="diagnostic-text">IBSS Hub:</Typography>
                                    </Box>
                                    <Box>
                                    <Typography className="diagnostic-text">{`${this.state.IBSS_Hub_Version || this.labels.funcUnknown_S} \n`}</Typography>
                                    </Box>
                                </Box>
                                <Box display={'flex'}>
                                    <Box width={'40%'} className="d-flex justify-content-end" mr={1}>
                                        <Typography className="diagnostic-text">IBSS API:</Typography>
                                    </Box>
                                    <Box>
                                    <Typography className="diagnostic-text">{`${this.state.IBSS_API || this.labels.funcUnknown_S} \n`}</Typography>
                                    </Box>
                                </Box>
                                <Box display={'flex'}>
                                    <Box width={'40%'} className="d-flex justify-content-end" mr={1}>
                                        <Typography className="diagnostic-text">IBSS User API:</Typography>
                                    </Box>
                                    <Box>
                                    <Typography className="diagnostic-text">{`${this.state.IBSS_User_API || this.labels.funcUnknown_S} \n`}</Typography>
                                    </Box>
                                </Box>
                                <Box display={'flex'}>
                                    <Box width={'40%'} className="d-flex justify-content-end" mr={1}>
                                        <Typography className="diagnostic-text">IBSS Data Entry API:</Typography>
                                    </Box>
                                    <Box>
                                    <Typography className="diagnostic-text">{`${this.state.IBSS_Data_Entry_API || this.labels.funcUnknown_S} \n`}</Typography>
                                    </Box>
                                </Box>
                                <Box display={'flex'}>
                                    <Box width={'40%'} className="d-flex justify-content-end" mr={1}>
                                        <Typography className="diagnostic-text">IBSS Data API:</Typography>
                                    </Box>
                                    <Box>
                                    <Typography className="diagnostic-text">{`${this.state.IBSS_Data_API || this.labels.funcUnknown_S} \n`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        }
                        footer=
                        {
                            <IbssButton
                                color="primary"
                                variant="contained"
                                size="medium"
                                className="mr-2"
                                onClick={() => this.handleDiagnosticsModal()}
                                tabIndex={0}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButton>
                        }
                    />

                    <OnBoarding
                        open={this.state.onBoardingModal}
                        closePopup={() => this.setState({ onBoardingModal: false })}
                    />

                    <Box className="m-title navbar-title page-title">
                        <Box>
                        <Typography className="navbar-title-font" variant="h2" gutterBottom>{this.state.title || spaceTitle || mainTitle}</Typography>
                        </Box>
                    </Box>

                    <Box className="d-flex">
                        <ul className="nav-list vertical-center" >
                            {logoPath && (
                                <img
                                    src={logoPath}
                                    alt="company logo"
                                    height="50"
                                    className="mr-10"
                                />
                            )}
                            <li className="dropdown">
                                <IbssButton variant="outlined" className="dropbtn d-flex align-items-center" onClick={() => this.toggleUserMenu()}>
                                    <img alt="" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/User.svg`} width="16" />
                                    <span className="mx-3">{displayName}</span>
                                    <KeyboardArrowDownIcon className="menuI" />
                                    {/* <i className="fa fa-angle-down"></i> */}
                                </IbssButton>
                                {this.state.showMenu && (
                                    <div className="dropdown-content" id="myDropdown"
                                        onMouseLeave={() =>
                                        {
                                            this.setState({ showMenu: !this.state.showMenu });
                                        }}
                                    >
                                        <IbssButton fullWidth
                                            className="switchTheme"
                                            onClick={() =>
                                            {
                                                this.toggleUserMenu();
                                                this.updateTheme();
                                                dispatch({ type: SWITCH_THEME });
                                            }}
                                        >
                                            {lightModeTheme
                                                ? this.labels.HubLabelSwitchToDarkMode
                                                : this.labels.HubLabelSwitchToLightMode}
                                        </IbssButton>
                                        <hr className="my-0" />
                                        {this.local.getUserDetails().userType === "Native" &&
                                            <>
                                                <IbssButton fullWidth className="switchTheme" onClick={() => this.handleModal()}>{this.labels.HubLabelChangePassword}</IbssButton>
                                                <hr className="my-0" />
                                            </>
                                        }

                                        <IbssButton fullWidth className="switchTheme" onClick={() => this.handleDiagnosticsModal()}>{this.labels.HubLabelDiagnostics}</IbssButton>
                                        <hr className="my-0" />
                                        <IbssButton fullWidth className="switchTheme" onClick={() =>
                                        {
                                            this.session.clear();
                                            this.local.clear();
                                            this.props.history.push("/login");
                                        }}>
                                            {this.labels.HubLabelLogout}
                                        </IbssButton>
                                    </div>
                                )}
                            </li>
                            <li className="dropdown ml-2">
                                <IbssToolTip title={this.labels.HubMenuNotifications} arrow>
                                    <div>
                                    <IbssButton variant="outlined" aria-label="notifications" className="px-8 dropbtn d-flex align-items-center relative px-7 height-auto"
                                        onClick={() => this.redirectToNotification()}
                                    >
                                        <NotificationIcon />
                                        {newNotification && (
                                            <span className="notification-circle"></span>
                                        )}
                                    </IbssButton>
                                    </div>
                                </IbssToolTip>
                            </li>
                        </ul>
                    </Box>
                </div>
            </>
        );
    }
};

const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria,
        newNotification: state.newNotification
    };
};

export default connect(mapStateToProps)(Header);

export interface IProps extends RouteComponentProps, IPropsFromState
{
}

export interface IState
{
    title: string;
    isModalShow: boolean;
    isShowDiagnosticsModal: boolean;
    IBSS_Hub_Version: string;
    IBSS_API: string;
    IBSS_User_API: string;
    IBSS_Data_Entry_API: string;
    IBSS_Data_API: string;
    showMenu: boolean;
    show: boolean;
    oldPassword: string;
    newPassword: string;
    reTypePassword: string;
    type: string;
    email: string;
    valid: boolean;
    showError: boolean;
    errorMessage: string;
    whatsNewLink: string;
    onBoardingModal: boolean;
}
