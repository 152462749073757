import { Component } from 'react';
import { Box, Card, Grid, SelectChangeEvent, Typography } from '@mui/material';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import { IUserPreferences, IUserPreferencesNode } from '../../../../Providers.Api/UserPreferences/UserPreferenceRepository';
import { InputOption } from '../../../../Components/Inputs/SelectList2/InputSelectBox';
import Helper from '../../../../Common/Helper';
import IbssAutocomplete from '../../../../Components/Inputs/AutoComplete/IbssAutocomplete';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { PagedResponse } from '../../../../Providers.Api/Models';
import { SystemFilter } from './DataModels';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import { string } from 'slate';
import { PageHelper } from './PageHelper';


class SearchResult extends Component<any, IState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider

    constructor(props: any)
    {
        super(props)
        this.state = {
            searchResults: "List",
            searchResultUserPref: PageHelper.getUserPrefrenceJson(),
            isLoading: false,
            buildingOptions: new Array<InputOption>(),
            filterOptions: new Array<InputOption>(),
            searchText: null,
            selectedFilter: null,
            nodes: new Array<IUserPreferencesNode>,
            selectedBuildingId: null,
        };
    };

    public async componentDidMount(): Promise<void>
    {

        //configure search results input
        const response = this.local.getUserPreferences();
        const buildingMapped = response.Nodes.map((building) =>
        {
            return { Name: Helper.getBuildingNameUsingBuildingId(building.NodeId), Node_Id: building.NodeId, DefaultFilter: building?.DefaultFilter ? building?.DefaultFilter[0]?.Filter_Id : '' };
        })


        await this.setState({
            buildingOptions: buildingMapped.map(i => new InputOption(i.Name, i.Node_Id ? i.Node_Id.toString() : '')).filter((i) => i.label !== ""),
            searchResults: response?.SearchPrefs?.DefaultSearchResults as string,
            searchResultUserPref: response,
        });
    };

    public searchResultChanged(value: string): void
    {
        this.setState({ searchResults: value });
    };

    public async submit(): Promise<void>
    {
        const payload = {
            ...this.state.searchResultUserPref,
            Nodes: this.state.nodes,
            SearchPrefs:
            {
                DefaultBuilding: this.state.searchResultUserPref.SearchPrefs.DefaultBuilding,
                DefaultSearchResults: this.state.searchResults
            },

        };
        try
        {
            await this.userPreferences.update(payload);
            this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
        }
        catch { }
    }

    private async buildingChanged(buildingId: number): Promise<void>
    {
        const filters = await appContext().ibssApiClientV2.v2.byNodeid.filters.get<PagedResponse<SystemFilter[]>>({
            nodeId: buildingId,
            select: SystemFilter,
        });
        const defaultFilter = await this.state.searchResultUserPref.Nodes.find((node) => node.NodeId === buildingId);
        await this.setState({ filterOptions: filters.value.map(i => new InputOption(i.Name, i.Filter_Id ? i.Filter_Id.toString() : '')), selectedBuildingId: buildingId ? buildingId : null, selectedFilter: defaultFilter?.DefaultFilter?.length ? defaultFilter?.DefaultFilter[0].Filter_Id : null });
    }

    private async filterChanged(filter: string): Promise<void>
    {
        const userPref = this.local.getUserPreferences();
        const DefaultFloor = userPref.Nodes.find((i: IUserPreferencesNode) => i.NodeId == this.state.selectedBuildingId)?.DefaultFloor as number | null;
        const DefaultZone = userPref.Nodes.find((i: IUserPreferencesNode) => i.NodeId == this.state.selectedBuildingId)?.DefaultZone as number | null;
        const DefaultFilter = userPref.Nodes.find((i: IUserPreferencesNode) => i.NodeId == this.state.selectedBuildingId)?.DefaultFilter ?? [];

        const payload: IUserPreferencesNode = {
            NodeId: this.state.selectedBuildingId,
            DefaultFloor: DefaultFloor,
            DefaultZone: DefaultZone,
            FavouriteSpaces: [],
            FavouriteAmenities: [],
            FavouriteEquipment: [],
            DefaultFilter: [{ Filter_Id: filter ?? '', EventType_Id: '' }]
        }
        this.setState({ selectedFilter: filter, nodes: [...this.state.nodes, payload] });

    }

    render()
    {
        return (
            <>
                <Grid container >
                    <Card className='card-userPref'>
                        <Grid
                            container
                            gap={1}
                        >
                            <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleSearchResult}</Typography>

                            <Typography className='card-userPref-text mb-32' variant="body2" gutterBottom>{this.labels.HubSubTitleSearchResult}</Typography>

                        </Grid>
                        <Grid container columns={16} className="mt-4 search-reasult">

                            <Grid item xs={8} className="d-flex justify-content-center align-items-center" onClick={() => this.searchResultChanged("List")} sx={{ backgroundColor: this.state.searchResults === "List" ? "var(--ui-primary-pastel)" : null, borderRadius: this.state.searchResults === "List" ? "8px" : null, border: this.state.searchResults === "List" ? "2px solid var(--ui-primary)" : null }}>
                                <div className='p-24'>
                                    <div className='d-block'>
                                        <img alt="List View" src="/images/List view.svg" width="128px" />
                                    </div>
                                    <div className='d-flex justify-content-center mt-8'>
                                        <IbssButton className='btn-radius' variant={`${this.state.searchResults === "List" ? "contained" : "text"}`}>{this.labels.HubButtonList}</IbssButton>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={8} className={`d-flex justify-content-center align-items-center ${this.state.searchResults === "Map" ? "search-result-active" : null}`} onClick={() => this.searchResultChanged("Map")}>
                                <div className='p-24'>
                                    <div className='d-block'>
                                        <img alt="Map View" src="/images/Map view.svg" width="128px" />
                                    </div>
                                    <div className='d-flex justify-content-center mt-8'>
                                        <IbssButton className='btn-radius' variant={`${this.state.searchResults === "Map" ? "contained" : "text"}`} >{this.labels.HubButtonMap}</IbssButton>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                container
                                gap={1}
                                className="mt-2"
                            >
                                <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleDefaultFilter}</Typography>
                                <Typography className='card-userPref-text mb-32' variant="body2" gutterBottom>{this.labels.HubSubTitleDefaultFilter}</Typography>
                            </Grid>
                            <Grid container gap={1}
                                className="mt-2">
                                <IbssFormControl fullWidth className='mb-3'>
                                    <IbssInputDropDown
                                        inputLabel={this.labels.HubLabelBuilding}
                                        options={this.state.buildingOptions}
                                        value={this.state.selectedBuildingId}
                                        id={"buildingSelection"}
                                        onChange={(e: SelectChangeEvent<string>) => this.buildingChanged(parseInt(e.target.value))}
                                        fullWidth
                                    />
                                </IbssFormControl>
                                <IbssFormControl fullWidth className='mb-3'>
                                    <IbssInputDropDown
                                        inputLabel={this.labels.HubMenuBookings}
                                        options={this.state.filterOptions}
                                        value={this.state.selectedFilter}
                                        id={"buildingSelection"}
                                        onChange={(e: SelectChangeEvent<string>) => this.filterChanged(e.target.value)}
                                        fullWidth
                                    />
                                </IbssFormControl>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => this.submit()}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
};

export default SearchResult;

export interface IState
{
    searchResults: string;
    isLoading: boolean;
    searchResultUserPref: IUserPreferences;
    selectedBuildingId: number | null;
    searchText: string | null;
    selectedFilter: string | null;
    buildingOptions: Array<InputOption>;
    filterOptions: Array<InputOption>;
    nodes: Array<IUserPreferencesNode>;
}
