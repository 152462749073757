import React from 'react';
import * as ReactDOM from 'react-dom';
import { appContext } from '../../../../AppContext';
import { RouteComponentProps } from 'react-router-dom';
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { DateTime, Interval } from 'luxon';
import { DateHelper } from '../../../../Common/DateHelper';
import { Day, Week, WorkWeek, Month, Agenda, ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Inject, TimelineViews,  GroupModel, ResourceDetails, RenderCellEventArgs, EventRenderedArgs, PopupOpenEventArgs, SelectEventArgs } from '@syncfusion/ej2-react-schedule';
import { Internationalization } from '@syncfusion/ej2-base';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssIconButton from '../../../../Components/Buttons/IconButton/IbssIconButton';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TodayIcon from '@mui/icons-material/Today';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { Box, Grid, IconButton, SvgIcon, Typography, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import EditBookingDrawer from './EditBookingDrawer';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { PagedResponse } from '../../../../Providers.Api/Models';
import { Filter, Booking } from './DataModels';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { IUserPreferences } from '../../../../Providers.Api/UserPreferences/UserPreferenceRepository';
import { IDeleteTagToEventType, ITagToEventType, ICreateTagResponse } from './DataModels';
import { ParentBookingRelationTypeEnum } from '../../../../Providers.Api/Bookings/GetV2BookingsEndpoint';
import IconHousing from './IconHousing';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BookingTagsDialog from './BookingTagsDialog';
import SpaceInfoDialog from '../../../../Components/Dialogs/SpaceInfoDialog/SpaceInfoDialog';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BookingsCount from './BookingsCount';
import IbssToolTip from '../../../../Components/Miscellaneous/Tooltip/IbssToolTip';
import { ReactComponent as NoSpaceScheduleViewIcon } from '../ViewSchedule/NoSpacesScheduleView.svg';
import "./ViewBookingsManagedSchedule.scss"

class ViewBookingsManagedSchedule extends IbssPage<IProps, IState>
{
    private get apiCache() { return appContext().apiCache; }
    private get appState() { return appContext().state; }
    private get bookingService () { return appContext().bookingService; }
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; }
    private userPreferences = {} as IUserPreferences;
    private scheduleRef: React.RefObject<ScheduleComponent>;
    private instance: Internationalization = new Internationalization();
    private canReadTags: boolean;
    private canReadFilters: boolean;

    constructor(props: IProps)
    {
        super(props);
        this.scheduleRef = React.createRef<ScheduleComponent>();
        this.canReadTags = this.local.hasRight('DATAMODEL.Tag2EventType.Read');
        this.canReadFilters = this.local.hasRight('DATAMODEL.Filters.Read');
        this.state =
        {
            bookings: [],
            bookingsFormat: 'schedule',
            buildingId: 0,
            buildingStartHrs: '06:00',
            buildingEndHrs: '23:00',
            filters: [],
            isLoading: false,
            linkedSpacesIds: [],
            selectedBooking: undefined,
            selectedFilterId: '',
            selectedSpaceId: '',
            showEditBooking: false,
            showSpaceInfoDialog: false,
            showBookingTagsDialog: false,
            skipTokenBookings: '',
            skipTokenFilters: '',
            slotCount: 2, // number of time slots to be allocated for the specified time interval (60 minutes) duration on schedule view
            spaces: [],
            spacePageIndex: 0,
            startTime: DateTime.now(),
            tagsToEvents: [],
            userPreferenceFilterId: '',
            tagToCreate: {} as TagToEventTypeView,
            tagToRemove: '',
        };
    }

    public async componentWillMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
    }

    public async componentDidMount(): Promise<void>
    {
        // load config 
        this.pageTitle = this.labels.HubLabelBookings;
        this.userPreferences = this.local.getUserPreferences();

        // make api calls
        await this.refreshFilters();
        await this.refreshSpacesAndBookings();
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {

        if(prevState.buildingId !== this.state.buildingId)
        {
            // if user selects another building, route to new url containing updated buildingId,
            const { history, match } = this.props;
            if (match.params !== null)
            {
                history.push(match.path.replace(":buildingid", this.state.buildingId.toString()));
            }

            await this.refreshFilters();
            await this.refreshSpacesAndBookings();
            return;
        }

        if(prevState.startTime !== this.state.startTime)
        {
            await this.refreshSpacesAndBookings();
        }

        if(prevState.tagToCreate?.tagId !== this.state.tagToCreate?.tagId && this.state.tagToCreate?.tagId)
        {
            await this.createBookingTag();
            return;
        }

        if(prevState.tagToRemove !== this.state.tagToRemove && this.state.tagToRemove)
        {
            await this.removeBookingTag(this.state.tagToRemove);
            return;
        }
    }

    private getSpaceIdsFromQuery(query: string): string[]
    {
        const conditions = query.split(' or ');
        const spaceIds = conditions.map(condition => 
            {
                const match = condition.match(/Space_Id eq '(.*)'/);
                return match ? match[1] : '';
            }
        );

        const filteredSpaceIds = spaceIds.filter(value =>  value !== '');
        return filteredSpaceIds;
    }

    private filterSpacesBySpaceIds(spaces: SpaceView[], spaceIds: string[]): SpaceView[]
    {
        // if either no spaceIds are parsed in the system filter query or the incorrect spaceIds are used, return no spaces from the spaces cache.
        const defaultSpaces: SpaceView[] = [];
        if(spaceIds.length > 0)
        {
            const filteredSpaces = spaces.filter(space => spaceIds.includes(space.spaceId));
            return filteredSpaces.length > 0 ? filteredSpaces : defaultSpaces;
        } 
        else
        {
            return defaultSpaces;
        }
    }

    private async loadCachedSpaces(): Promise<void>
    {
        const filterQueryString = (this.state.filters.find(filter => filter.filterId === this.state.selectedFilterId))?.filter;

        const spaceIds = filterQueryString? this.getSpaceIdsFromQuery(filterQueryString): [];

        try
        {
            const response = await this.apiCache.getSpacesByBuilding(this.state.buildingId);
            const spaceView = response.map(i => SpaceView.fromSpace(i));
            const spaces = spaceView.sort((a, b) => a.spaceName.toLocaleLowerCase().localeCompare(b.spaceName.toLocaleLowerCase())); // sort spaces by Ascending Order on loading cached spaces.

            await this.setStateAsync({
                spaces: this.filterSpacesBySpaceIds(spaces, spaceIds),
                spacePageIndex: 0,
            });
        }
        catch
        {
            return;
        }
    }

    private async refreshSpacesAndBookings(): Promise<void>
    {
        this.setState({isLoading: true});
        await this.loadCachedSpaces();
        await this.loadLinkedSpaces();
        await this.loadBookings();
        if(this.canReadTags)
        {
            await this.loadBookingTagToEventTypes();
        }
        this.setState({isLoading: false});
    }

    private async loadLinkedSpaces(): Promise<void>
    {
        // spaces cache does not have linked spaces ids.
        // this function acts on spaces, parse space.spaceLayout, writes the spaceIds into a linkedSpacesIds state.
        const spaceLayouts = this.state.spaces
        .filter(space => space.spaceLayout !== "")
        .flatMap(space => 
            {
                try
                {
                    const layouts: ISpaceLayout[] = JSON.parse(space.spaceLayout)?.Layouts ?? [];
                    if(layouts.every( layout => this.isSpaceLayoutType(layout)))
                    {
                        return layouts;
                    }
                    else
                    {
                        return null;
                    }
                }
                catch
                {
                    return null;
                }
            }
        )
        .filter(space => space !== null && space.Space_Id.includes(';')) as ISpaceLayout[];

        const linkedSpaceIds = spaceLayouts.map(j => j.Space_Id );
        const uniqueLinkedSpaceIds = new Set(linkedSpaceIds);
        await this.setStateAsync({linkedSpacesIds: Array.from(uniqueLinkedSpaceIds)});
    }

    private isSpaceLayoutType(obj: any): obj is ISpaceLayout
    {
        // the parsed JSON's type begins as any, this function puts some type guard on the returned obj.
        return "Name" in obj && "Space_Id" in obj && typeof obj['Name'] === 'string' && typeof obj['Space_Id'] === 'string';
    }

    private linkedSpaceIdIncludesSpaceId(linkeSpaceId: string, spaceIds: string[]): boolean
    {
        return spaceIds.some(spaceId => linkeSpaceId.split(';').includes(spaceId));
    }

    private async loadBookingTagToEventTypes(): Promise<void>
    {
        // load all tagToEvents associated with building
        try
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.get<PagedResponse<ITagToEventType[]>>({
                nodeId: this.state.buildingId,
                select: ITagToEventType, 
                top: 1000,
                recursive: true,
            });


            const tagsToEvents = response.value.map(tagToEventType => TagToEventTypeView.fromTagToEventType(tagToEventType));
            this.setState({ tagsToEvents: tagsToEvents });
        }
        catch(error)
        {
            return;
        }
    }

    private createFlexBookingURL(spaceId: string): string
    {
        return `/flex-find-a-space/${this.state.buildingId}/searchaspace/${spaceId}`;
    }

    private clickCreateBookingBtn(spaceId: string, start: DateTime, end: DateTime): void
    {
        const { history } = this.props;
        this.session.setFlexSpaceSearchCriteria(start, end);
        history.push(this.createFlexBookingURL(spaceId));
    }

    private async setUserPrefsFilter(): Promise<void>
    {
        const buildingSpecificUserSearchPrefs = this.userPreferences.Nodes.find(building => building.NodeId === this.state.buildingId);

        const userPrefsFilters = buildingSpecificUserSearchPrefs?.DefaultFilter ?? '';
        if(userPrefsFilters && userPrefsFilters[0])
        {
            await this.setStateAsync({ userPreferenceFilterId: userPrefsFilters[0].Filter_Id});
        }
    }

    private async loadFilters(): Promise<void>
    {
        this.setState({isLoading: true});
        try 
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.filters.get<PagedResponse<Filter[]>>({
                nodeId: this.state.buildingId,
                top: 1000,
                select: Filter,
                skipToken: this.state.skipTokenFilters
            });
         
            if(response.value)
            {
                const newFilters = response.value.map(filter => FilterView.fromFilter(filter));
                
                this.setState
                ({
                    filters: newFilters,
                    skipTokenFilters: response.skipToken,
                    isLoading: false,
                });
            }
           
        }
        finally
        {
            this.setState({isLoading: false});
        }
    }

    private getFilterOptions(): ILabelOption[]
    {
        return this.state.filters.filter(filter => filter.status !=='StatusInactive').map((filter) => ({label: filter.name, value: filter.filterId}));
    }

    private async refreshFilters(): Promise<void>
    {
        this.setStateAsync({ filters: [], userPreferenceFilterId: '', selectedFilterId: ''});
        await this.setUserPrefsFilter();
        await this.loadFilters();

        // booking filter used is in order of userPreference filter (by buildingId), first filter's filterId in the list of state.filters and finally empty string.
        const userPreferredActiveFilterId = this.state.userPreferenceFilterId && this.state.filters.find(filter => filter.filterId === this.state.userPreferenceFilterId && filter.status ==='StatusActiveDefault')?.filterId;
        const filter = userPreferredActiveFilterId || this.state.filters.find(filter=> filter.status !=='StatusInactive')?.filterId || '';
        this.setStateAsync({selectedFilterId: filter});
    }

    private async changeSelectedFilter(newFilterValue: string): Promise<void>
    {
        this.setState({isLoading: true});
        await this.setStateAsync({selectedFilterId: newFilterValue});
        await this.refreshSpacesAndBookings()
        this.setState({isLoading: false});
    }

    private async loadBookings(): Promise<void>
    {
        // keep startTime in local time, and when calculating the start and end of date for API interaction, convert to new building timezone, whilst keeping local time and dates. e.g. 15th of feb in the uk would return 15th of feb in Sydney
        const startOfTodayWithZone = this.state.startTime.startOf('day').setZoneByNode(this.state.buildingId);
        const endOfTodayWithZone = this.state.startTime.endOf('day').setZoneByNode(this.state.buildingId);
        
        const startOfTodayUTC = startOfTodayWithZone.toUtcByNode(this.state.buildingId).toISO();
        const endOfTodayUTC = endOfTodayWithZone.toUtcByNode(this.state.buildingId).toISO();

        const selectedFilter = this.state?.filters?.filter(bookingFilter => bookingFilter.filterId === this.state.selectedFilterId)[0];
        
        const filteredValue = selectedFilter ? selectedFilter.filter : "";

        const spaceIds = this.getSpaceIdsFromQuery(filteredValue);
        const linkedSpaceIds = this.state.linkedSpacesIds.filter(id => this.linkedSpaceIdIncludesSpaceId(id, spaceIds));
        const linkedSpaceIdsQuery = linkedSpaceIds.map(linkedSpaceId => ` or Space_Id eq '${linkedSpaceId}'`).join('');
        
        const filterQuery = `${filteredValue}${linkedSpaceIdsQuery}`;

        let filter = `Booking_Status ne 'Cancelled' and Booking_Status ne 'Auto Cancelled' and Booking_Start ge datetime'${startOfTodayUTC}' and Booking_End lt datetime'${endOfTodayUTC}'`;
        if (filterQuery) 
        {
            filter += ` and (${filterQuery})`;
        }

        try
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.bookings.get<PagedResponse<Booking[]>>({
                nodeId: this.state.buildingId,
                top: 1000,
                skipToken: this.state.skipTokenBookings,
                select: Booking,
                filter: filter,
            });

            const newBookings = response.value.map(booking => BookingView.fromBooking(booking));

            this.setState({ bookings: newBookings});
        }
        catch
        {
            return;
        }
    }

    private addTag(tag: TagToEventTypeView): void
    {
        this.setState({tagToCreate: tag});
    }

    private async createBookingTag(): Promise<void>
    {
        try
        {
            this.setState({isLoading: true});
            const response = await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.post<ICreateTagResponse>({
                nodeId: this.state.tagToCreate.nodeId,
                body: [{
                    Tag_Id: this.state.tagToCreate.tagId,
                    EventType_Id: this.state.tagToCreate.eventTypeId,
                    TagType: this.state.tagToCreate.tagType,
                    Name:  this.state.tagToCreate.name,
                    Value:  this.state.tagToCreate.value,
                    Icon: this.state.tagToCreate.icon,
                    Node_Id: this.state.tagToCreate.nodeId,
                    Record_Id: this.state.tagToCreate.recordId,
                }]
            });

            const tagsToEvents = response.Details.map(tagToEventType => TagToEventTypeView.fromTagToEventType(tagToEventType));
            this.setState({ 
                tagsToEvents: [...this.state.tagsToEvents.filter(tag => !tagsToEvents.find(tagToEvent => tagToEvent.tagToEventTypeId === tag.tagToEventTypeId)), ...tagsToEvents],  
                tagToCreate: {} as TagToEventTypeView
            });
        }
        catch
        {
            return;
        }
        finally
        {
            this.setState({isLoading: false});
        }
    }

    private removeTag(tagToEventTypeId: string): void
    {
        this.setState({tagToRemove: tagToEventTypeId});
    }

    private async removeBookingTag(tagToEventTypeId: string): Promise<void>
    {
        const selectedTag = this.state.tagsToEvents.find(tag => tag.tagToEventTypeId === tagToEventTypeId);
        if(!selectedTag)
        {
            return;
        }
        try
        {
            this.setState({isLoading: true});
            const response = await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.byTag2Eventtypeid.delete<IDeleteTagToEventType>({
                nodeId: selectedTag.nodeId,
                tag2Eventtypeid: tagToEventTypeId,
            });

            if(response)
            {
                this.setState({
                    tagsToEvents: this.state.tagsToEvents.filter(tag => tag.tagToEventTypeId !== tagToEventTypeId),
                    tagToRemove: '',
                });
            }
        }
        catch(error)
        {
            return;
        }
        finally
        {
            this.setState({isLoading: false});
        }
    }

    private changeBookingsFormat(newBookingsFormat: string): void
    {
        if(newBookingsFormat && newBookingsFormat !== this.state.bookingsFormat)
        {
            this.setState({bookingsFormat: newBookingsFormat});
            
            const startTime = this.state.startTime.startOf('day');
            const endTime = this.state.startTime.endOf('day');
            this.session.setOnelensBookingSearchCriteria(startTime, endTime, this.state.selectedFilterId);
            this.props.history.push(`/operational-services-bookings/${this.state.buildingId.toString()}/0`);
        }
    }

    private redirectToAdminSystemFiltersPage(): void
    {
        this.props.history.push(`/admin/system-filters/buildingId=${this.state.buildingId.toString()}`);
    }

    private transformBookings(): IScheduleDate[]
    {
        const bookings = this.state.bookings.flatMap(booking =>
            {
                const tagsOnBooking = this.state.tagsToEvents.filter(tag => tag.recordId === booking.bookingId); 
                const scheduleDatesForBooking = booking.spaceId.split(';').map(spaceId =>
                {
                    const isMainBooking = !(booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Setup || booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Teardown);
                    const scheduleDate: IScheduleDate =
                    {
                        // Id, Subject, StartTime & EndTime fields (default action event data field names) cannot be written as camelCase strings without first mapping camelCase field names to ScheduleComponent.eventSettings.fields
                        // e.g. fields: { subject: { title: 'subject', name: 'subject'}}, make sure value of name property matches the camelCase field name.
                        id: booking.bookingId, 
                        subject: isMainBooking? booking.bookingName : ' ',
                        bookingOwnerEmail: booking.bookingOwnerEmail,
                        bookingOwnerName: booking.bookingOwnerName,
                        startTime: booking.bookingStart.toJSDate(),
                        endTime: booking.bookingEnd.toJSDate(),
                        bookingStatus: booking.bookingStatus,
                        spaceId: spaceId,
                        categoryColor: this.processBookingColour(booking),
                        icons: tagsOnBooking.map(tag => <IconHousing tagToEventTypeId={tag.tagToEventTypeId} name={tag.name} tagType={tag.tagType} value={tag.value} location={tag.icon.split(';')[0]} />),
                        parentBookingRelationType: booking.parentBookingRelationType,
                    }
                    return scheduleDate;
                });

                return scheduleDatesForBooking;
            });

        return bookings;
    }

    private processBookingColour(booking: BookingView): string
    {
        if(booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Setup || booking.parentBookingRelationType === ParentBookingRelationTypeEnum.Teardown) 
        {
            return '#DAE9F5'; // --Light-Pastel-Blue
        }      
        else if (booking.bookingStatus === 'New' || booking.bookingStatus === 'Amended')
        {
            return '#FFF'; // white colour for bookings which haven't started.
        }
        else if(booking.bookingStatus === 'Completed')
        {
            return '#FFF'; // white colour for completed bookings
        }
        else if(booking.bookingStatus === 'No Show')
        {
            return '#FFBBBF'; //-Light-Pastel-Red
        }
        else if(booking.bookingStatus === 'In Progress')
        {
            return '#CCF4DD'; //--Light-Pastel-Green
        }
        else if(booking.bookingStatus === 'Early Check In')
        {
            return '#DCE1E5'; // --Light-Mid-Tone
        }
        else if(booking.bookingStatus === 'Late Checkin')
        {
            return '#FFF3CC'; // --Light-Pastel-Yellow
        }  
        else
        {
            return 'black';
        }
    }

    private groupData: GroupModel = 
    {
        // group bookings by spaces - in ResourceDirective with name="Spaces", matching on SpaceId field of bookings transformed by transformBookings() and id field of ResourceDirective's datasource.
        resources: ['Spaces']
    }

    private getSpaceName(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.name;
    }

    private getFloorName(value: ResourceDetails): string
    {
        const availableSpaces = this.local.getNodeData();
        const floor = availableSpaces.Regions.flatMap(i => i.Buildings).flatMap(i => i.Floors).find(i => i && i.Node_Id == (value as ResourceDetails).resourceData.nodeId);
        return floor?.Node_Name ?? '';
    }

    private getSpaceCapacity(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.capacity;
    }

    private getSpaceId(value: ResourceDetails): string
    {
        return (value as ResourceDetails).resourceData.id;
    }

    private spaceInfoClicked(spaceId: string): void
    {
        const selectedSpace = this.state.spaces.find(room => room.spaceId === spaceId);
        if (!selectedSpace)
        {
            return;
        }
        this.setState({ selectedSpaceId: spaceId, showSpaceInfoDialog: true });
    }

    private onPopupOpen(args: PopupOpenEventArgs): void
    {
        if(args.type === 'QuickInfo' && args?.data?.id)
        {
            args.cancel = true;

            const selectedBooking = this.state.bookings.find(booking => {
                    const data = args.data as IScheduleDate;
                    return booking.bookingId === data.id
                }
            )

            this.setState({
                showEditBooking: true,
                selectedBooking: selectedBooking,
            });            
        }
        else if(args.type === 'QuickInfo' && args?.data?.id === undefined)
        {
            // create booking popup that appears when user clicks on a slot on the schedule
            const eventDetails = args.data;
            const targetElement = document.querySelector(`.e-popup-content`);
            if(targetElement)
            {
                ReactDOM.render(
                    <Box display='flex' justifyContent={'center'}>
                        <IbssButton
                            variant={'contained'} 
                            onClick={()=> this.clickCreateBookingBtn(eventDetails?.spaceId, DateTime.fromJSDate(args.data?.startTime), DateTime.fromJSDate(args.data?.endTime))}
                        >
                            {/* Create a booking */ }
                            {this.labels.funcScheduleViewCreateBooking_S}
                        </IbssButton>
                    </Box>,
                    targetElement
                )
            }
        }
        else
        {
            // disables ALL other popups besides 'QuickInfo'
            args.cancel = true;
        }
    }


    private resourceHeaderTemplate(spaceDetails: ResourceDetails): JSX.Element
    {
        return (
            <div className="template-wrap">
                <IbssToolTip
                    arrow={true} 
                    title={this.getSpaceName(spaceDetails)}
                    placement='bottom'
                    slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -14],
                              },
                            },
                          ],
                        },
                    }}
                >
                    <div className="room-name">
                        {this.getSpaceName(spaceDetails)}
                    </div>
                </IbssToolTip>
                <div className="room-type">{this.getFloorName(spaceDetails)}</div>
                <div className="room-capacity">{this.getSpaceCapacity(spaceDetails)}</div>
                <div id="room-info">
                    <IbssIconButton
                        aria-label="info"
                        key={this.getSpaceId(spaceDetails)}
                        sx={{ padding: 0 }}
                        onClick={() => this.spaceInfoClicked(spaceDetails.resourceData.id)}
                    >
                        <SvgIcon fontSize={'small'} component={InfoIcon}></SvgIcon>
                    </IbssIconButton>
                </div>
            </div>
        );
    }

    private getTimeString(value: Date)
    {
        return this.instance.formatDate(value, { skeleton: 'hm' });
    }

    private eventTemplate(props: IScheduleDate): JSX.Element
    {
        if(props.parentBookingRelationType === ParentBookingRelationTypeEnum.Main)
        {
            return (
                <div className="e-inner-wrap bookingsDashboard-appointment">
                    <div className="e-subject">{props.bookingOwnerName}</div>
                    <div className="e-time">{`${this.getTimeString(props.startTime)} - ${this.getTimeString(props.endTime)}`}</div>
                    <div className="e-location"></div>
                    <div>
                        {this.canReadTags &&
                            <>
                                <div className="icons-row">{props.icons.map((icon, key) => <div key={key}>{icon}</div>)}</div>
                                {props.icons.length > 0 &&
                                    <div>
                                        <MoreHorizIcon fontSize='small' onClick={() => this.setState({showBookingTagsDialog: true, selectedBooking: this.state.bookings.find(booking => booking.bookingId === props.id)})}/>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            )
        }
        else
        { 
            // else if booking type is setup or teardown
            return (
                <div className="e-inner-wrap bookingsDashboard-appointment">
                    <div className="setup-teardown-icons-container">
                        {this.canReadTags &&
                            <>
                                <div className="icons-row setup-teardown">{props.icons.map((icon, key)=> <div key={key}>{icon}</div>).slice(0,2)}</div>
                                {props.icons.length > 0 &&
                                <div>
                                    <MoreHorizIcon fontSize='small' onClick={() => this.setState({showBookingTagsDialog: true, selectedBooking: this.state.bookings.find(booking => booking.bookingId === props.id)})}/>
                                </div>
                                }
                            </>
                        }
                    </div>
                </div>
            )
        }
    }

    private renderCell(args: RenderCellEventArgs): void
    {
        if(args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td'))
        {
            // labels for Space, Floor, Cap (as in Capacity), 4th header is empty.
            const target: HTMLElement = args.element.querySelector('.e-resource-text') as HTMLElement;
            target.innerHTML = `<div class="name">${this.labels.HubLabelSpace}</div><div class="type">${this.labels.HubLabelFloor}</div><div class="capacity">${this.labels.HubLabelCapacity}</div><div class="capacity">${''}</div>`
        }
    }

    private onEventRendered(args: EventRenderedArgs): void
    {
        // before bookings are rendered on the scheduler, apply some styles to the bookings, but primarily to assign borderColor to the CategoryColor in the bookings data.

        (args.element as HTMLElement).style.color = '#263238'; // this is equivalent to var(ui--text), change to this var if syncfusion scheduler has dark mode.
        (args.element as HTMLElement).style.backgroundColor = args.data.categoryColor;
        (args.element as HTMLElement).style.borderColor = '#263238';
        (args.element as HTMLElement).style.borderRadius = '5px';
        (args.element as HTMLElement).style.borderLeftWidth = 'thick';
        (args.element as HTMLElement).style.opacity = '1';
    }

    private toggleDrawer (showEditBooking: boolean): void
    {
        this.setState({showEditBooking: showEditBooking});
    }

    private changeDate(event: DateTime): void
    {
        this.setState({startTime: event});
    }

    private reduceSlotCount(): void
    {
        if(this.state.slotCount> 1)
        {
            this.setState({slotCount: this.state.slotCount - 1});
        }
    }

    private addSlotCount(): void
    {
        if(this.state.slotCount < 6)
        {
            this.setState({slotCount: this.state.slotCount + 1});
        }
    }

    public render(): JSX.Element
    { 
        return (
            <>
            {
                <BookingTagsDialog 
                    show={this.state.showBookingTagsDialog} 
                    onClose={()=> this.setState({showBookingTagsDialog: false})}
                    tags={this.state.tagsToEvents.filter(tag => tag.recordId === this.state.selectedBooking?.bookingId)}
                />
            }
            {(this.state.isLoading) && <LoadingOverlay text={this.labels.HubLabelLoadingText} />}
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    <div className="table-panel">
                    {
                        <>
                            <Grid container rowSpacing={0} sx={{display:'flex',alignItems:'center',mt: 0, ml: 0}}>
                                <Grid item md={7} sx={{pt: 0, pb: 1.5}} >
                                    {/* Space Schedule */}
                                    <Box className="table-panel-header" component="div">{this.labels.funcBookingDashboard_S}</Box>
                                    <Box className='bookingsDashboard-subHeader'>
                                        <Typography variant='body1' className='bookingsDashboard-paragraph'>{this.labels.funcOverViewBookings_D}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={5} sx={{pt: 0, pb: 1.8}}>
                                    <IbssInputDropDown
                                        id='bookings-filters'
                                        inputLabel={this.labels.funcSelectSpaceFilter_S}
                                        options={this.getFilterOptions()}
                                        value={this.state.selectedFilterId}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>)=> this.changeSelectedFilter(e.target.value)}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{pt:0}}>
                                    <Box display='flex' justifyContent='space-between' gap='0.5rem'>
                                        <BookingsCount bookings={this.state.bookings}/>
                                        <Box display='inherit' gap='inherit'>
                                            <IbssButton 
                                                variant='contained'
                                                disableElevation={true} 
                                                sx={{backgroundColor: (theme) => theme.palette.background.default, color: (theme) => theme.palette.text.primary}} 
                                                onClick={()=> this.refreshSpacesAndBookings()}
                                            >
                                                <Box display='flex' gap='8px'>
                                                    <AutorenewIcon />
                                                    <Typography className='bookingsDashboard-text'>{this.labels.funcRefreshBookings_S}</Typography>
                                                </Box>
                                            </IbssButton>
                                            <ToggleButtonGroup
                                                aria-label='bookings format'
                                                exclusive
                                                value={this.state.bookingsFormat}
                                                onChange={(event, newBookingsFormat) => this.changeBookingsFormat(newBookingsFormat)}
                                            >
                                                <ToggleButton value='list' aria-label='bookings list format'>
                                                    <FormatListBulletedIcon fontSize='small'/>
                                                </ToggleButton>
                                                <ToggleButton value='schedule' aria-label='bookings schedule format'>
                                                    <CalendarTodayIcon fontSize='small'/>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={12} sx={{pt:1.5}} >
                                    <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            backgroundColor: (theme) => theme.palette.common.uiCore,
                                        }}>
                                        <Box width='100%' display={'flex'} justifyContent={'space-between'} paddingTop='0.5rem' paddingBottom='0.5rem'>
                                            <Box display={'flex'}>
                                                <Box sx={{padding: '0rem 1rem'}}>
                                                    <IbssIconButton
                                                        aria-label="navigate-to-previous-day"
                                                        key={"navigate-to-previous-day"}
                                                        onClick={()=> this.setState({startTime: this.state.startTime.plus({days: -1}) })}
                                                    >
                                                        <SvgIcon fontSize={'medium'} component={ChevronLeftIcon}></SvgIcon>
                                                    </IbssIconButton>
                                                    <IbssIconButton
                                                        aria-label="navigate-to-next-day"
                                                        key={"navigate-to-next-day"}
                                                        onClick={()=> this.setState({startTime: this.state.startTime.plus({days: 1}) })}
                                                    >
                                                        <SvgIcon fontSize={'medium'} component={ChevronRightIcon}></SvgIcon>
                                                    </IbssIconButton>
                                                </Box>
                                                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                                                    <DesktopDatePicker
                                                        value={this.state.startTime}
                                                        onChange={(event) => this.changeDate(event as DateTime)}
                                                        renderInput={(params) => 
                                                            {
                                                                const { sx, ...paramsMinusSx } = params;
                                                                let paramsMinusSxUpdated = { ...paramsMinusSx, inputProps: { ...paramsMinusSx.inputProps, onChange: () => { } } }
                                                                return <TextField
                                                                            size="small"
                                                                            {...paramsMinusSxUpdated}
                                                                            sx={{
                                                                                width: "auto",
                                                                                "& legend": { display: "none" },
                                                                                "& fieldset": { top: 0 },
                                                                            }}
                                                                            error={false}
                                                                        />
                                                            }
                                                        }
                                                    />
                                                </LocalizationProvider>      
                                                <IbssButton
                                                    aria-label="navigate-today"
                                                    key={"navigate-today"}
                                                    sx={{marginLeft: '2rem', color: (theme) => theme.palette.text.primary}} 
                                                    onClick={() => this.setState({startTime: DateTime.now()})}
                                                >
                                                    <SvgIcon fontSize={'medium'} component={TodayIcon}></SvgIcon>
                                                    <Typography className='bookingsDashboard-text' paddingLeft={'0.5rem'}>
                                                        {this.labels.funcCalendarJumpToToday_S} 
                                                    </Typography>
                                                </IbssButton>
                                            </Box>
                                            <Box paddingRight='1rem'>
                                                <IconButton
                                                    onClick={()=> this.addSlotCount()}
                                                    disabled={this.state.slotCount === 6} 
                                                    sx={{ backgroundColor: (theme) => theme.palette.background.default}}>
                                                    <AddIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={()=> this.reduceSlotCount()}
                                                    disabled={this.state.slotCount === 1}  
                                                    sx={{ backgroundColor: (theme) => theme.palette.background.default}}>
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Box>                                           
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            {this.state.spaces.length > 0 &&
                                <ScheduleComponent
                                    cssClass={'onelens-bookings-dashboard'}
                                    currentView="Day"
                                    height={this.state.spaces.slice(0, (this.state.spacePageIndex+1)*10).length <= 10? '62vh': 'auto'} 
                                    width='100%'
                                    ref={this.scheduleRef}
                                    // show time line indicator of current time only if browser timezone is equal to building timezone.
                                    showTimeIndicator={Intl.DateTimeFormat().resolvedOptions().timeZone === DateHelper.getZoneByNode(this.state.buildingId)}
                                    timeScale={{enable: true, interval: 60, slotCount: this.state.slotCount}}
                                    eventSettings=
                                    {{
                                        dataSource: this.transformBookings(),
                                        fields: 
                                        {
                                            id: { title: 'id', name: 'id'},
                                            subject: { title: 'subject', name: 'subject'},
                                            startTime: { title: 'start time', name: 'startTime' },
                                            endTime:  { title: 'end time', name: 'endTime' },
                                        },
                                        template: this.eventTemplate.bind(this),
                                    }}
                                    group={this.groupData}
                                    showHeaderBar={false}
                                    startHour={`${this.state.buildingStartHrs.split(':')[0]}:00`} //ensure the start hour is a whole hour rather than 07:15.
                                    endHour={this.state.buildingEndHrs}
                                    workHours={{highlight: true, start: this.state.buildingStartHrs, end: this.state.buildingEndHrs}}
                                    eventRendered={this.onEventRendered.bind(this)}
                                    popupOpen={this.onPopupOpen.bind(this)}
                                    quickInfoTemplates={{footer: ()=> {return <div></div>}}} // display nothing in place of the default syncfusion quickInfo popup footer.
                                    renderCell={this.renderCell.bind(this)}
                                    resourceHeaderTemplate={this.resourceHeaderTemplate.bind(this)}
                                    selectedDate={this.state.startTime.toJSDate()}
                                    rowAutoHeight={true} // property that allows the timeline row containing appointment cells to expand to accommodate multiple appointments at the same time, as well as displaying singluar appointments properly.
                                >
                                    <ResourcesDirective>
                                        <ResourceDirective
                                            field='spaceId' // has to match to a field of the data passed to ScheduleComponent eventSettings DataSource.  
                                            title='Space Id' // string displayed when one clicks on the booking
                                            name='Spaces' // match to a string in the arrya of groupData.resources
                                            allowMultiple={true}
                                            idField='id'  
                                            textField='name'
                                            colorField='Color'
                                            dataSource={this.state.spaces.map(space => 
                                                ({
                                                    id: space.spaceId, 
                                                    capacity: space.spaceCapacity,
                                                    name: space.spaceName, 
                                                    nodeId: space.nodeId, 
                                                    type: space.spaceType
                                                })
                                            )}
                                        />
                                    </ResourcesDirective> 
                                    <Inject services={[TimelineViews]}/>
                                    <ViewsDirective>
                                        <ViewDirective option='TimelineDay'/>
                                    </ViewsDirective>
                                </ScheduleComponent>
                            }
                            {
                                 this.state.spaces.length === 0 && this.state.isLoading === false &&
                                 <Box sx={{height: '62vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <NoSpaceScheduleViewIcon />
                                    <Typography variant="h5" className='bookingsDashboard-paragraph' sx={{mt: 4, mb: 2}}>
                                        {this.labels.funcScheduleNoSpaces_L}
                                    </Typography>
                                    {
                                        this.canReadFilters &&
                                        <>
                                            {
                                                this.state.filters.length > 0 && <Typography className='bookingsDashboard-paragraph'>
                                                    {this.labels.funcBookingsNoSpaces_D}
                                                </Typography>
                                                
                                            }
                                            {
                                                this.state.filters.length === 0 && <Typography className='bookingsDashboard-paragraph'>
                                                    {this.labels.funcBookingsCreateFilter_L}
                                                </Typography>
                                                
                                            }

                                            <IbssButton 
                                                size='large'
                                                className='bookingsDashboard-paragraph'
                                                onClick={()=> this.redirectToAdminSystemFiltersPage()}
                                            >
                                                <Typography variant='body1'>{this.labels.funcBookingsManageFilters_S}</Typography>
                                            </IbssButton>
                                        </>
                                    }
                                    {
                                        !this.canReadFilters &&
                                        <>
                                            <Typography className='bookingsDashboard-paragraph'>
                                                {this.labels.funcBookingsSelectAnotherFilter_S}
                                            </Typography>
                                        </>
                                    }
                                 </Box>
                            }
                            {
                                this.state.selectedBooking &&
                                <EditBookingDrawer 
                                    open={this.state.showEditBooking}
                                    toggleDrawer={this.toggleDrawer.bind(this)}
                                    bookingId={this.state.selectedBooking.bookingId}
                                    bookingDescription={this.state.selectedBooking.bookingDescription}
                                    bookingEarlyCheckIn={this.state.selectedBooking.bookingEarlyCheckin}
                                    bookingEnd={this.state.selectedBooking.bookingEnd}
                                    bookingStart={this.state.selectedBooking.bookingStart}
                                    bookingIsApproved={this.state.selectedBooking.bookingIsApproved}
                                    bookingName={this.state.selectedBooking.bookingName}
                                    bookingOwnerEmail={this.state.selectedBooking.bookingOwnerEmail}
                                    bookingOwnerName={this.state.selectedBooking.bookingOwnerName}
                                    bookingStatus={this.state.selectedBooking.bookingStatus}
                                    selectedBookingTags={this.state.tagsToEvents.filter(tag => tag.recordId === this.state.selectedBooking?.bookingId)}
                                    spaceLayout={this.state.selectedBooking.spaceLayout}
                                    spaceName={this.state.selectedBooking.spaceName}
                                    buildingId={this.state.buildingId}
                                    changeIsLoading={(loadingStatus: boolean): void => this.setState({isLoading: loadingStatus})}
                                    isLoading={this.state.isLoading}
                                    spaceId={this.state.selectedBooking.spaceId}
                                    nodeId={this.state.selectedBooking.nodeId}
                                    addTag={this.addTag.bind(this)}
                                    removeTag={this.removeTag.bind(this)}
                                    refreshSpacesAndBookings={()=> this.refreshSpacesAndBookings()}
                            />
                            }
                        </>
                        }
                    </div>
                </div>
            </div>
            <SpaceInfoDialog
                isOpen={this.state.showSpaceInfoDialog}
                onClose={() => this.setState({ showSpaceInfoDialog: false })}
                spaceId={this.state.selectedSpaceId}
                nodeId={this.state.spaces.find(space => space.spaceId === this.state.selectedSpaceId)?.nodeId ?? 0}
                buildingId={this.state.buildingId} 
            />
            </>
        )
    }
}

export default ViewBookingsManagedSchedule;

export interface IProps extends RouteComponentProps
{
}

export interface IState
{
    bookings: BookingView[],
    bookingsFormat: string,
    buildingId: number,
    buildingStartHrs: string,
    buildingEndHrs: string,
    filters: FilterView[],
    isLoading: boolean,
    linkedSpacesIds: string[],
    selectedBooking: BookingView | undefined,
    selectedFilterId: string,
    selectedSpaceId: string,
    showEditBooking: boolean,
    showSpaceInfoDialog: boolean,
    showBookingTagsDialog: boolean,
    skipTokenBookings: string,
    skipTokenFilters: string,
    slotCount: number,
    spaces: SpaceView[],
    spacePageIndex: number,
    startTime: DateTime,
    tagsToEvents: TagToEventTypeView[],
    tagToCreate: TagToEventTypeView,
    tagToRemove: string,
    userPreferenceFilterId: string,
}

export class SpaceView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceCapacity = 0;
    public spaceClass = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public spaceLayout = "";
    public spaceSetup = 0;
    public spaceStatus = "";
    public imageURI = "";
    public bookingPolicyId = "";
    public metaBookable = 0;
    public metaOccType = 0;
    public metaLocZone = "";
    public metaServReqsCatering = 0;
    public metaServReqsAV = 0;
    public metaServReqsHearing = 0;
    public metaServReqsPresentation = 0;
    public spaceWorkType = "";
    public meetingLinkAvailable = 0;

    public static fromSpace(space: Space): SpaceView
    {
        return {
            nodeId: space.Node_Id,
            spaceId: space.Space_Id,
            spaceName: space.Space_Name,
            spaceCapacity: space.Space_Capacity,
            spaceClass: space.Space_Class,
            spaceType: space.Space_Type,
            spaceTypeLabel: space.Space_Type_Label,
            spaceLayout: space.Space_Layout,
            spaceSetup: space.Space_Setup,
            spaceStatus: space.Space_Status,
            imageURI: space.ImageURI,
            bookingPolicyId: space.Booking_Policy_Id,
            metaBookable: space.Meta_Bookable,
            metaOccType: space.Meta_Occ_Type,
            metaLocZone: space.Meta_Loc_Zone,
            metaServReqsCatering: space.Meta_Serv_Reqs_Presentation,
            metaServReqsAV: space.Meta_Serv_Reqs_AV,
            metaServReqsHearing: space.Meta_Serv_Reqs_Hearing,
            metaServReqsPresentation: space.Meta_Serv_Reqs_Presentation,
            spaceWorkType: space.Space_Work_Type,
            meetingLinkAvailable: space.Meta_Ext_Booking_System,
        };
    }
}

export interface ISpaceLayout
{
    Space_Id: string;
    Name: string;
}

interface IScheduleDate
{
    id: string,
    subject: string,
    startTime: Date,
    endTime: Date,
    bookingStatus: string,
    bookingOwnerEmail: string,
    bookingOwnerName: string,
    spaceId: string, // singular, delimited spaceId, e.g.  spaceId: 1CC_03-NE-R005;1CC_03-SE-R006
    categoryColor: string,
    icons: JSX.Element[],
    parentBookingRelationType: ParentBookingRelationTypeEnum,
}

export class BookingView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceLayout = ""
    public bookingId = "";
    public bookingName = "";
    public bookingDescription = "";
    public bookingStart = DateHelper.null();
    public bookingEarlyCheckin = DateHelper.null();
    public bookingEnd = DateHelper.null();
    public bookingStatus = "";
    public bookingIsActive = 0;
    public bookingIsApproved = 0;
    public bookingOwnerEmail = "";
    public bookingOwnerName = "";
    public bookingParties = "";
    public bookingShareLocation = 0;
    public createdAt = DateHelper.null();
    public createdBy = "";
    public parentBookingId = "";
    public parentBookingRelationType = ParentBookingRelationTypeEnum.Main;

    public static fromBooking(booking: Booking): BookingView
    {
        return {
            nodeId: booking.Node_Id,
            spaceId: booking.Space_Id,
            spaceName: booking.Space_Name,
            spaceLayout: booking.Space_Layout,
            bookingId: booking.Booking_Id,
            bookingName: booking.Booking_Name,
            bookingDescription: booking.Booking_Description,
            bookingStart: DateHelper.fromIsoByNode(booking.Booking_Start as unknown as string, booking.Node_Id),
            bookingEarlyCheckin: DateHelper.fromIsoByNode(booking.Booking_Early_Checkin as unknown as string, booking.Node_Id),
            bookingEnd: DateHelper.fromIsoByNode(booking.Booking_End as unknown as string, booking.Node_Id),
            bookingStatus: booking.Booking_Status,
            bookingIsActive: booking.Booking_IsActive,
            bookingIsApproved: booking.Booking_IsApproved,
            bookingOwnerEmail: booking.Booking_Owner_Email,
            bookingOwnerName: booking.Booking_Owner_Name,
            bookingParties: booking.Booking_Parties,
            bookingShareLocation: booking.Booking_Share_Loc,
            createdAt: DateHelper.fromIsoByNode(booking._CreatedAt as unknown as string, booking.Node_Id),
            createdBy: booking._CreatedBy,
            parentBookingId: booking.Parent_Booking_Id,
            parentBookingRelationType: booking.Parent_Booking_Relation_Type,
        };
    }
}

class FilterView
{
    public filterId = '';
    public eventTypeId = 0;
    public filter = '';
    public status = '';
    public name = '';
    public nodeId = 0;
    public owndedByUserId = '';
    public createdByUserId = '';
    public createdAt = DateHelper.null();
    public modifiedByUserId = '';
    public modifiedAt = DateHelper.null();
    public concurrencyStamp = '';

    public static fromFilter(filter: Filter): FilterView
    {
        return {
            filterId: filter.Filter_Id,
            eventTypeId: filter.EventType_Id,
            filter: filter.Filter,
            status: filter.Status,
            name: filter.Name,
            nodeId: filter.Node_Id,
            owndedByUserId: filter.OwnedByUserId,
            createdByUserId: filter.CreatedByUserId,
            createdAt: DateHelper.fromIsoByNode(filter.CreatedAt, filter.Node_Id),
            modifiedByUserId: filter.ModifiedByUserId,
            modifiedAt: DateHelper.fromIsoByNode(filter.ModifiedAt, filter.Node_Id),
            concurrencyStamp: filter.ConcurrencyStamp,
        }
    }
}

export class TagToEventTypeView
{
    public nodeId = 0;
    public tagToEventTypeId = "";
    public tagId = "";
    public tagType = "";
    public name = "";
    public value = "";
    public icon = "";
    public recordId = "";
    public eventTypeId = 0;

    public static fromTagToEventType(tagToEventType: ITagToEventType): TagToEventTypeView
    {
        return {
            nodeId: parseInt(tagToEventType.Node_Id),
            tagToEventTypeId: tagToEventType.Tag2EventType_Id,
            tagId: tagToEventType.Tag_Id,
            tagType: tagToEventType.TagType,
            name: tagToEventType.Name,
            value: tagToEventType.Value,
            icon: tagToEventType.Icon,
            recordId: tagToEventType.Record_Id,
            eventTypeId: tagToEventType.EventType_Id,
        }
    }
}

interface ILabelOption 
{
    label: string; 
    value: string;
}